import { faPlusSquare } from "@fortawesome/pro-light-svg-icons";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";
import ERPIcon from "Components/ERPIcon";
import { Toast } from "Components/Toast";
import { isNil } from "lodash";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import MixService from "Services/mix.service";
import { MixModel } from "types/models";
import MixModal, { MixContextType, MixModalContext } from "./MixModal";
import MixSelectItem from "./MixSelectItem";
import { gtag } from 'ga-gtag';

export interface MixSelectMenuProps {
    mixes: MixModel[];
    selectedMix: MixModel;
    show: boolean;
    style?: any;
    onMixSelected: (nextMixId: string) => void;
    updateMixes: (nextMixes: MixModel[]) => void;
}

const MixSelectMenu = React.forwardRef(
    ({ mixes, selectedMix, show, style, onMixSelected, updateMixes }: MixSelectMenuProps, ref: any) => {
        const [mixModalContext, setMixModalContext] = useState<MixModalContext>(null);

        const confirm = useConfirmation();

        const handleMixSelected = (nextMix: MixModel) => {
            if (nextMix.id === selectedMix?.id) {
                return;
            }

            onMixSelected(nextMix.id);
        };

        const handleMixCreated = () => {
            setMixModalContext({ type: MixContextType.CREATE });
        };

        const handleMixEdit = (mix: MixModel) => {
            setMixModalContext({ type: MixContextType.EDIT, mix: mix });
        };

        const handleMixDelete = async (mix: MixModel) => {
            const result = await confirm({
                title: "Delete mix",
                description: `Are you sure you want to delete ${mix.name}?`
            });

            if (!result.success) {
                return;
            }

            await MixService.deleteMix(mix.id);

            Toast.success(`Successfully deleted ${mix.name}`);
            onMixDelete(mix);
        };

        const onMixDelete = (mix: MixModel) => {
            if (selectedMix?.id === mix.id) {
                onMixSelected(null);
                localStorage.removeItem("selectedMixId");
            }

            const nextMixes = [...mixes.filter(m => m.id !== mix.id)];
            updateMixes(nextMixes);
        };

        const onMixCreated = (mix: MixModel) => {
            const selectedMixId = localStorage.getItem("selectedMixId") ?? null;

            if (isNil(selectedMixId)) {
                localStorage.setItem("selectedMixId", mix.id);
            }

            const nextMixes = [...mixes, mix];
            updateMixes(nextMixes);
            onMixSelected(mix.id);

            gtag('event', 'new mix', { name: mix.name });
        };

        const onMixEdit = (nextMix: MixModel) => {
            const nextMixes = [
                ...mixes.map(m => {
                    if (m.id === nextMix.id) {
                        return nextMix;
                    }

                    return m;
                })
            ];

            updateMixes(nextMixes);
        };

        return (
            <>
                {show && (
                    <div className="mix-select-menu" ref={ref} style={style}>
                        <div className="mix-select-new-button" onClick={() => handleMixCreated()}>
                            <ERPIcon icon={faPlusSquare} size="2x" />
                            Create new application mix
                        </div>

                        {mixes.map(m => {
                            return (
                                <Dropdown.Item
                                    key={m.id}
                                    as={MixSelectItem}
                                    mix={m}
                                    isSelected={selectedMix?.id === m.id}
                                    onMixSelected={handleMixSelected}
                                    onMixEdit={handleMixEdit}
                                    onMixDelete={handleMixDelete}
                                />
                            );
                        })}
                    </div>
                )}

                {!isNil(mixModalContext) && (
                    <MixModal
                        context={mixModalContext}
                        show
                        onClose={() => setMixModalContext(null)}
                        onMixEdit={onMixEdit}
                        onMixCreated={onMixCreated}
                    />
                )}
            </>
        );
    }
);

export default MixSelectMenu;

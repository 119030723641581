import clsx from "clsx";
import MixSelect, { MixSelectStyle } from "Components/MixSelect";
import NumericInput from "Components/NumericInput";
import Switch from "Components/Switch";
import { Toast } from "Components/Toast";
import Tooltip from "Components/Tooltip";
import { round } from "lodash";
import React, { ReactElement } from "react";
import MixConstituentService from "Services/mix-constituent.service";
import { ExploreConstituentModel, SprayMethod } from "types/models";
import { AddMixConstituentRequest } from "types/requests";
import { roundToString } from "utils/utils";
import { useExplore } from "./ExploreContext";
import { gtag } from 'ga-gtag';

export interface ConstituentsTableBodyRowProps {
    constituent: ExploreConstituentModel;
}

const ConstituentsTableBodyRow = ({ constituent }: ConstituentsTableBodyRowProps) => {
    const {
        selectedConstituentId,
        mixes,
        updateSelectedConstituentId,
        updateConstituentConcentration,
        updateConstituentApplicationRate,
        updateConstituentIsEnabled,
        updateMixes
    } = useExplore();

    const handleConstituentSelected = () => {
        if (selectedConstituentId === constituent.id) {
            updateSelectedConstituentId(null);
            return;
        }

        updateSelectedConstituentId(constituent.id);
    };

    const handleConcentrationChanged = (nextConcentration: number) => {
        updateConstituentConcentration(constituent, nextConcentration);
    };

    const handleApplicationRateChanged = (nextApplicationRate: number) => {
        updateConstituentApplicationRate(constituent, nextApplicationRate);
    };

    const handleIsEnabledChanged = (isChecked: boolean) => {
        updateConstituentIsEnabled(constituent, isChecked);
    };

    const handleAddToMix = async (mixId: string) => {
        const request: AddMixConstituentRequest = {
            constituentId: constituent.id,
            concentration: constituent.concentration ?? constituent.defaultConcentration,
            applicationRate: constituent.applicationRate ?? constituent.defaultApplicationRate,
            sprayMethod: SprayMethod.COMPLETE,
            cropId: constituent.cropId,
            isEnabled: true
        };

        await MixConstituentService.addMixConstituent(mixId, request);

        Toast.success("Successfully added constituent");

        gtag('event', 'add chemical', { constituent: constituent.name });
    };

    const isSelected = selectedConstituentId === constituent.id;

    const concentration = constituent.concentration ?? 0;
    const applicationRate = constituent.applicationRate ?? 0;
    const aquaticRisk = constituent.aquaticRisk ?? constituent.defaultAquaticRisk;
    const measureOfEffect = constituent.measureOfEffect ?? constituent.defaultMeasureOfEffect;

    const tooltipContent: ReactElement = (
        <div>
            <div>
                Aquatic risk score: <b>{roundToString(aquaticRisk, 0)}</b>
            </div>
            <div>
                Aquatic toxicity: <b>{roundToString(measureOfEffect, 1)}</b>
            </div>
            <div>
                Persistence and runoff likelihood:{" "}
                <b>{roundToString(constituent.defaultMeasureOfMobilityAndPersistance, 1)}</b>
            </div>
        </div>
    );

    return (
        <tr className={clsx({ selected: isSelected })}>
            <Tooltip tooltipContent={tooltipContent}>
                <td className="selectable-cell" onClick={() => handleConstituentSelected()}>
                    {constituent.name}
                </td>
            </Tooltip>

            <td className="selectable-cell right-align-cell" onClick={() => handleConstituentSelected()}>
                {round(constituent.defaultApplicationRate, 2)}
            </td>

            <td
                className="selectable-cell right-align-cell"
                style={{ fontWeight: 700 }}
                onClick={() => handleConstituentSelected()}
            >
                {roundToString(constituent.defaultAquaticRisk, 0)}
            </td>

            {isSelected && (
                <td className="blue-cell right-align-cell" style={{ minWidth: 100 }}>
                    <NumericInput
                        value={concentration}
                        min={0}
                        max={1000}
                        allowEmpty={true}
                        onChange={handleConcentrationChanged}
                    />
                </td>
            )}

            {!isSelected && (
                <td className="selectable-cell blue-cell right-align-cell" onClick={() => handleConstituentSelected()}>
                    {concentration}
                </td>
            )}

            {isSelected && (
                <td className="blue-cell right-align-cell" style={{ minWidth: 100 }}>
                    <NumericInput
                        value={applicationRate}
                        min={0}
                        allowEmpty={true}
                        onChange={handleApplicationRateChanged}
                    />
                </td>
            )}

            {!isSelected && (
                <td className="selectable-cell blue-cell right-align-cell" onClick={() => handleConstituentSelected()}>
                    {applicationRate}
                </td>
            )}

            <td
                className="selectable-cell blue-cell right-align-cell"
                style={{ fontWeight: 700 }}
                onClick={() => handleConstituentSelected()}
            >
                {roundToString(aquaticRisk, 0)}
            </td>

            <td className="centered-cell">
                <Switch checked={constituent.isEnabled} onChecked={handleIsEnabledChanged} />
            </td>

            <td className="centered-cell">
                <MixSelect
                    mixes={mixes}
                    style={MixSelectStyle.ADD}
                    updateMixes={updateMixes}
                    onMixSelected={handleAddToMix}
                />
            </td>
        </tr>
    );
};

export default ConstituentsTableBodyRow;

import LoadingIndicator from "../../Components/LoadingIndicator";
import React, { useContext, useEffect, useState } from "react";
import { CropModel, ExploreConstituentModel, MixModel, PesticideType } from "../../types/models";
import { isNilOrEmpty } from "utils/utils";
import CalculationsService from "Services/calculations.service";
import CropService from "Services/crop.service";
import MixService from "Services/mix.service";
import { FilterOptions } from "./Filter";
import { isNil } from "lodash";
import { gtag } from 'ga-gtag';

interface ExploreContextProps {
    constituents: ExploreConstituentModel[];
    crops: CropModel[];
    selectedConstituentId: string;
    mixes: MixModel[];
    filterOptions: FilterOptions;
    updateConstituent: (nextConstituent: ExploreConstituentModel) => void;
    updateSelectedConstituentId: (nextConstituentId: string) => void;
    updateConstituentConcentration: (constituent: ExploreConstituentModel, nextConcentration: number) => void;
    updateConstituentApplicationRate: (constituent: ExploreConstituentModel, nextApplicationRate: number) => void;
    updateConstituentIsEnabled: (constituent: ExploreConstituentModel, isEnabled: boolean) => void;
    updateConstituentsIsEnabled: (constituents: ExploreConstituentModel[], isEnabled: boolean) => void;
    updateMixes: (nextMixes: MixModel[]) => void;
    fetchCropConstituents: (crop: CropModel) => Promise<void>;
    updateFilterOptions: (next: FilterOptions) => void;
}

interface ExploreContextOptions {
    children: React.ReactNode;
}

export const ExploreContext = React.createContext<ExploreContextProps | null>(null);

export const useExplore = () => useContext(ExploreContext);

const ExploreContextProvider = ({ children }: ExploreContextOptions) => {
    const [crops, setCrops] = useState<CropModel[]>([]);
    const [mixes, setMixes] = useState<MixModel[]>([]);
    const [constituents, setConstituents] = useState<ExploreConstituentModel[]>([]);
    const [selectedConstituentId, setSelectedConstituentId] = useState<string>(null);
    const [filterOptions, setFilterOptions] = useState<FilterOptions>(
        JSON.parse(localStorage.getItem("exploreFilterOptions")) ?? {
            cropCode: "sugar_cane",
            pesticideType: PesticideType.ALL
        }
    );

    useEffect(() => {
        const getData = async () => {
            const getCrops = await CropService.getCrops();
            const getMixes = await MixService.getMixes();

            const [_crops, _mixes] = await Promise.all([getCrops, getMixes]);

            setCrops(_crops);
            setMixes(_mixes);
        };

        getData();
    }, []);

    const fetchCropConstituents = async (crop: CropModel) => {
        const nextConstituents = await CropService.getCropConstituents(crop.id);

        const exploreConstituents: ExploreConstituentModel[] = nextConstituents.map(c => {
            return {
                ...c,
                concentration: null,
                applicationRate: null,
                measureOfEffect: null,
                aquaticRisk: null,
                isEnabled: true
            };
        });

        setConstituents(exploreConstituents);

        if (!isNilOrEmpty(exploreConstituents)) {
            setSelectedConstituentId(exploreConstituents[0].id);
        }
    };

    const updateConstituent = (nextConstituent: ExploreConstituentModel) => {
        setConstituents(currentConstituents => [
            ...currentConstituents.map(c => {
                if (c.id !== nextConstituent.id) {
                    return c;
                }

                if (isNil(nextConstituent.concentration) && isNil(nextConstituent.applicationRate)) {
                    return { ...nextConstituent, measureOfEffect: null, aquaticRisk: null };
                }

                const measureOfEffect = CalculationsService.calculateMeasureOfEffect(
                    nextConstituent.applicationRate ?? nextConstituent.defaultApplicationRate,
                    nextConstituent.concentration ?? nextConstituent.defaultConcentration,
                    nextConstituent.ecotoxicityThreshold,
                    nextConstituent.weight
                );

                const aquaticRisk = CalculationsService.calculateAquaticRisk(
                    measureOfEffect,
                    nextConstituent.defaultMeasureOfMobilityAndPersistance
                );

                return { ...nextConstituent, measureOfEffect: measureOfEffect, aquaticRisk: aquaticRisk };
            })
        ]);
    };

    const updateConstituentConcentration = (constituent: ExploreConstituentModel, nextConcentration: number) => {
        const nextConstituent = {
            ...constituent,
            concentration: nextConcentration
        };

        updateConstituent(nextConstituent);

        gtag('event', 'apply product concentration', {
            constituent: constituent.name,
            pubcrisCode: constituent.pubcrisCode,
            concentration: nextConcentration,
        });
    };

    const updateConstituentApplicationRate = (constituent: ExploreConstituentModel, nextApplicationRate: number) => {
        const nextConstituent = {
            ...constituent,
            applicationRate: nextApplicationRate
        };

        updateConstituent(nextConstituent);
    };

    const updateConstituentIsEnabled = (constituent: ExploreConstituentModel, isChecked: boolean) => {
        if (constituent.isEnabled === isChecked) {
            return;
        }

        const nextConstituent = {
            ...constituent,
            isEnabled: isChecked
        };

        updateConstituent(nextConstituent);
    };

    const updateConstituentsIsEnabled = (constituents: ExploreConstituentModel[], isChecked: boolean) => {
        setConstituents(currentConstituents => [
            ...currentConstituents.map(c => {
                if (constituents.some(cc => cc.id === c.id)) {
                    return { ...c, isEnabled: isChecked };
                }

                return c;
            })
        ]);
    };

    const updateMixes = (nextMixes: MixModel[]) => {
        setMixes(nextMixes);
    };

    const updateFilterOptions = (filterOptions: FilterOptions) => {
        setSelectedConstituentId(null);
        setFilterOptions(filterOptions);

        localStorage.setItem("exploreFilterOptions", JSON.stringify(filterOptions));
    };

    const updateSelectedConstituentId = (nextId: string) => {
        setSelectedConstituentId(nextId);

        const constituent = constituents.find(c => c.id === nextId);
        gtag('event', 'select chemical', {
            name: constituent?.name,
            pesticideType: constituent?.pesticideType,
            pubcrisCode: constituent?.pubcrisCode
        });
    };

    const isLoading = isNilOrEmpty(crops);

    return (
        <ExploreContext.Provider
            value={{
                crops: crops,
                constituents: constituents,
                selectedConstituentId: selectedConstituentId,
                mixes: mixes,
                filterOptions: filterOptions,
                updateConstituent: updateConstituent,
                updateSelectedConstituentId,
                updateConstituentConcentration: updateConstituentConcentration,
                updateConstituentApplicationRate: updateConstituentApplicationRate,
                updateConstituentIsEnabled: updateConstituentIsEnabled,
                updateConstituentsIsEnabled: updateConstituentsIsEnabled,
                updateMixes: updateMixes,
                fetchCropConstituents: fetchCropConstituents,
                updateFilterOptions: updateFilterOptions
            }}
        >
            {isLoading && <LoadingIndicator className="explore-loading" centered />}

            {!isLoading && children}
        </ExploreContext.Provider>
    );
};

export default ExploreContextProvider;

import { PTEnvironment } from "./types";

class Config {
    public readonly apiUrl: string;
    public readonly environment: PTEnvironment;
    public readonly auth0: Auth0Config;
    public readonly ai: ApplicationInsightsConfig;
    public readonly gaCode: string;

    constructor() {
        this.environment = process.env.REACT_APP_PT_ENVIRONMENT as PTEnvironment;
        this.apiUrl = process.env.REACT_APP_API_BACKEND_URI;
        this.gaCode = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE;

        this.auth0 = new Auth0Config();
        this.ai = new ApplicationInsightsConfig();
    }
}

class Auth0Config {
    public readonly audience: string;
    public readonly clientId: string;
    public readonly redirectUri: string;

    constructor() {
        this.audience = process.env.REACT_APP_AUTH0_AUDENCE_API;
        this.clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
        this.redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URI;
    }
}

class ApplicationInsightsConfig {
    public readonly connectionString: string;

    constructor() {
        this.connectionString = process.env.REACT_APP_AI_CONNECTION_STRING;
    }
}

export default new Config();

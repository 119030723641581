import PTConstituentsChart from "Components/PTConstituentsChart";
import { isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { ChartScaleOptions, PesticideType } from "types/models";
import { isNilOrEmpty } from "utils/utils";
import ChartOptionsPanel from "./ChartOptionsPanel";
import ConstituentInfo from "./ConstituentInfo";
import ConstituentsTable from "./ConstituentsTable";
import { useExplore } from "./ExploreContext";
import HowToPanel from "./HowToPanel";
import ConstituentInfoProductList from "./ConstituentInfoProductsList";
import { gtag } from 'ga-gtag';

export interface ChartOptions {
    scale: ChartScaleOptions;
    showLabels: boolean;
}

const ExploreBody = () => {
    const {
        crops,
        constituents,
        selectedConstituentId,
        filterOptions,
        updateSelectedConstituentId,
        fetchCropConstituents
    } = useExplore();

    const [chartOptions, setChartOptions] = useState<ChartOptions>({
        scale: ChartScaleOptions.LOG,
        showLabels: false
    });

    const onChartOptionsChanged = (nextOpts: ChartOptions) => {
        setChartOptions(nextOpts);

        gtag('event', 'change chart options', {
            scale: nextOpts.scale,
            showLabels: nextOpts.showLabels,
        });
    };

    const filteredConstituents = useMemo(() => {
        if (isNilOrEmpty(constituents)) {
            return constituents;
        }

        if (filterOptions.pesticideType !== PesticideType.ALL) {
            return constituents.filter(c => c.pesticideType === filterOptions.pesticideType);
        }

        return constituents;
    }, [constituents, filterOptions]);

    useEffect(() => {
        if (isNil(filterOptions.cropCode) || !crops.some(c => c.code === filterOptions.cropCode)) {
            return;
        }

        const loadCropConstituents = async () => {
            await fetchCropConstituents(crops.find(c => c.code === filterOptions.cropCode));
        };

        loadCropConstituents();
    }, [filterOptions.cropCode]);

    const selectedConstituent = constituents.find(c => c.id === selectedConstituentId);

    return (
        <div className="explore-body">
            <div className="explore-section">
                <HowToPanel />

                <ConstituentsTable constituents={filteredConstituents} />

                {!isNil(selectedConstituent) && <ConstituentInfoProductList constituent={selectedConstituent} />}
            </div>

            <div className="explore-section">
                <ChartOptionsPanel chartOptions={chartOptions} onChartOptionsChanged={onChartOptionsChanged} />

                <PTConstituentsChart
                    constituents={filteredConstituents.filter(c => c.isEnabled)}
                    selectedConstituentId={selectedConstituentId}
                    chartScale={chartOptions.scale}
                    showLabels={chartOptions.showLabels}
                    className="constituents-chart-padding"
                    handleUpdateSelectedConstituentId={updateSelectedConstituentId}
                />

                {!isNil(selectedConstituent) && <ConstituentInfo constituent={selectedConstituent} />}
            </div>
        </div>
    );
};

export default ExploreBody;

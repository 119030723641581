import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { AssessConstituentModel } from "types/models";
import { useAssess } from "./AssessContext";
import MixView from "./MixView";
import MixViewContextProvider from "./MixViewContext";
import { gtag } from 'ga-gtag';

export interface MixViewStateModel {
    mixId: string;
    constituents: AssessConstituentModel[];
    selectedConstituentId: string;
    isModified: boolean;
    isSaving: boolean;
    shouldLoadConstituents: boolean;
}

const DEFAULT_STATE = {
    mixId: null,
    constituents: [],
    selectedConstituentId: null,
    isModified: false,
    isSaving: false,
    shouldLoadConstituents: true
};

export interface AssessBodyProps {
    selectedMixId: string;
}

const AssessBody = ({ selectedMixId }: AssessBodyProps) => {
    const { mixes, updateHasUnsavedChanges } = useAssess();

    const [primaryMixState, setPrimaryMixState] = useState<MixViewStateModel>({
        ...DEFAULT_STATE,
        mixId: mixes.some(m => m.id === selectedMixId) ? selectedMixId : null
    });
    const [compareMixState, setCompareMixState] = useState<MixViewStateModel>(DEFAULT_STATE);

    const handleMixClosed = (closedMixId: string) => {
        const notClosedMixContext = compareMixState.mixId === closedMixId ? primaryMixState : compareMixState;

        if (notClosedMixContext.mixId !== primaryMixState.mixId) {
            localStorage.setItem("selectedMixId", notClosedMixContext.mixId);
        }

        setPrimaryMixState(notClosedMixContext);
        setCompareMixState(DEFAULT_STATE);
    };

    const updateCompareMixId = (nextCompareMixId: string) => {
        setCompareMixState(currentState => {
            return { ...currentState, mixId: nextCompareMixId };
        });

        gtag('event', 'compare mixes');
    };

    useEffect(() => {
        if (primaryMixState.isModified || compareMixState.isModified) {
            updateHasUnsavedChanges(true);
            return;
        }

        updateHasUnsavedChanges(false);
    }, [primaryMixState.isModified, compareMixState.isModified]);

    const handleUpdatePrimaryMixState = (nextMixState: MixViewStateModel) => {
        if (nextMixState.mixId !== primaryMixState.mixId) {
            localStorage.setItem("selectedMixId", primaryMixState.mixId);
        }

        setPrimaryMixState(nextMixState);
    };

    return (
        <div className="assess-body">
            <MixViewContextProvider
                mixState={primaryMixState}
                isComparing={!isNil(compareMixState.mixId)}
                isPrimaryMix={true}
                updateMixState={handleUpdatePrimaryMixState}
                updateCompareMixId={updateCompareMixId}
                onMixClosed={handleMixClosed}
            >
                <MixView />
            </MixViewContextProvider>

            {!isNil(compareMixState.mixId) && (
                <MixViewContextProvider
                    mixState={compareMixState}
                    isComparing={true}
                    isPrimaryMix={false}
                    updateMixState={setCompareMixState}
                    onMixClosed={handleMixClosed}
                >
                    <MixView />
                </MixViewContextProvider>
            )}
        </div>
    );
};

export default AssessBody;
